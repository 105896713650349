import './src/styles/main.scss'

import React from 'react'

import { ModalProvider } from '@islandsstofa/react'
import { SearchProvider } from '@src/context/searchContext'
import { MobileMenuProvider } from '@src/context/mobileMenuContext'
import { SessionProvider } from '@src/context/sessionContext'

const wrapRootElement = ({ element }) => (
  <SearchProvider>
    <ModalProvider>
      <SessionProvider>
        <MobileMenuProvider>{element}</MobileMenuProvider>
      </SessionProvider>
    </ModalProvider>
  </SearchProvider>
)

export { wrapRootElement }
